import { useState, useEffect } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { Helmet } from 'react-helmet';

import AppHandler from './AppHandler';
import { DomainAccessedContext } from './utils/Contexts';
import ErrorBoundary from './utils/ErrorBoundary';
import './css/globals.css';


function MainComponent() {

  useEffect(() => {
    // Check the domain name
    const hostname = window.location.hostname;

    if (hostname === '0.0.0.0' || hostname === 'localhost') {
      document.title = 'Testing Locally';
      // Perform other actions for local testing
    } else if (hostname === 'www.domain1.com') {
      document.title = 'Welcome to Domain 1';
      // Perform other actions specific to domain1.com
    } else if (hostname === 'www.domain2.com') {
      document.title = 'Welcome to Domain 2';
      // Perform other actions specific to domain2.com
    }
  }, []);

  return (
    <>
      {/* <Helmet>
        <title>{title}</title>
      </Helmet> */}
      <Router>
        <AppHandler />
      </Router>
    </>
  );
}

export default function App() {
  return (
    <ErrorBoundary>
      <MainComponent />
    </ErrorBoundary>
  );
}
